<template>
  <div>
    <v-card>
      <v-card-title>
        Quotation Detail
      </v-card-title>
    </v-card>
    <h3 class="mt-5">Customer Info</h3>

    <v-card class="mt-3">
      <table class="table table-striped table-bordered">
        <tbody>
          <tr>
            <td class="bold">Name:</td>
            <td>{{ data.name }}</td>
          </tr>
          <tr>
            <td class="bold">Comapany:</td>
            <td>{{ data.company }}</td>
          </tr>
          <tr>
            <td class="bold">Email:</td>
            <td>{{ data.email }}</td>
          </tr>
          <tr>
            <td class="bold">Phone Number:</td>
            <td>{{ data.phoneNumber }}</td>
          </tr>

          <tr>
            <td class="bold">Created At:</td>
            <td>
              {{ $moment(data.created_at).format("DD-MMMM-YYYY ")}} {{ $moment(data.created_at).fromNow() }}
            </td>
          </tr>
          <tr></tr>
        </tbody>
      </table>
    </v-card>

    <h3 class="mt-5">Project Specifications</h3>

    <v-card class="cardContainer">
      <table class="table table-striped table-bordered">
        <tbody>
          <tr>
            <td class="bold">Product:</td>
            <td>{{ data.product }}</td>
          </tr>
          <tr>
            <td class="bold">Sample Size:</td>
            <td>{{ data.sampleSize }}</td>
          </tr>
          <tr>
            <td class="bold">Incidence Rate:</td>
            <td>{{ data.ir }}</td>
          </tr>
          <tr>
            <td class="bold">Length of interview:</td>
            <td>{{ data.lengthOfInterview }}</td>
          </tr>

          <tr>
            <td class="bold">Country:</td>
            <td>
              {{ data.country }}
            </td>
          </tr>
          <tr>
            <td class="bold">Age group:</td>
            <td>
              <ul v-for="age in data.ageGroup" :key="age._id">
                <li>{{ age }}</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td class="bold">Deliverable:</td>
            <td>
              {{ data.deliverable }}
            </td>
          </tr>
          <tr></tr>
        </tbody>
      </table>
    </v-card>

    <h3 class="mt-5">Cost</h3>

    <v-card class="cardContainer">
      <table class="table table-striped table-bordered">
        <tbody>
          <tr>
            <td class="bold">Cost - Local Currency:</td>
            <td>{{ data.localCurrency }}</td>
          </tr>
          <tr>
            <td class="bold">Cost - USD Currency:</td>
            <td>{{ data.usd }}</td>
          </tr>
        </tbody>
      </table>
    </v-card>
  </div>
</template>

<script>
import RestResource from "../../services/dataServiceBuyer";
const service = new RestResource();

export default {
  data() {
    return {
      data: [],
    };
  },

  mounted() {
    this.fetchQuotaion();
  },

  methods: {
    fetchQuotaion() {
      this.$setLoader();
      service.fetchQuotation(this.$route.params.id).then((res) => {
        this.data = res.data.data;
        this.$disableLoader();
      });
    },
  },
};
</script>


<style scoped lang="scss">
@import "~bootstrap/scss/bootstrap.scss";

.cardContainer {
    margin-top: 30px;
}
</style>